<template>
    <div
        class="gdpr"
        :class="{ show: showCookieBanner, mb: isMobileDevice }"
    >
        <div class="content d-flex">
            <div class="text">
                <p class="title mb-3">
                    {{ $t('modal.consent.heading') }}
                </p>
                <p class="sm-sbold">
                    {{ cookieText }}
                    <router-link :to="{ name: 'Pagina', params: { slug: 'cookie-uri' } }">
                        {{ $t('modal.consent.linkText') }}
                    </router-link>
                    .
                </p>
            </div>
            <div class="actions">
                <b-button
                    variant="primary"
                    class="lg fill"
                    @click="accept()"
                >
                    {{ $t('modal.consent.acceptButton') }}
                </b-button>
                <b-button
                    variant="primary"
                    class="lg stroke"
                    @click="deny()"
                >
                    {{ $t('modal.consent.denyButton') }}
                </b-button>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapMutations, mapState } from 'vuex';

    import UserAgentMixin from '@/mixins/userAgentMixin';

    export default {
        mixins: [UserAgentMixin],
        name: 'GdprCookieBanner',
        computed: {
            ...mapState('general', [
                'showCookieBanner',
                'cookieText',
            ]),
        },
        methods: {
            ...mapMutations('general', ['setCookieBanner']),
            accept() {
                localStorage.setItem('cookieIsAccepted', true);
                this.setCookieBanner(false);
                this.$emit('updateCookie', true);
            },
            deny() {
                this.setCookieBanner(false);
            },
        },
    };
</script>
<style scoped lang="scss">
.gdpr {
  box-shadow: $shadow;
  border-radius: 8px;
  background-color: $white;
  position: fixed;
  left: 20px;
  bottom: 20px;
  width: 30%;
  max-width: 400px;
  min-width: 250px;
  display: none;
  padding: 24px;

  &.mb {
    @include media-breakpoint-down(lg) {
      width: 75%;
      max-width: 75%;
      z-index: 999;
      max-height: calc(100vh - 40px);
      height: fit-content;
    }
    @include media-breakpoint-down(sm) {
      width: calc(100% - 40px);
      max-width: calc(100% - 40px);
    }
    .content {
      @include media-breakpoint-down(lg) {
        gap: 12px;
      }
      .text {
        p {
          @include media-breakpoint-between(sm, lg) {
            font-size: 13px;
            line-height: 21px;
          }
          &.title {
            font-size: 16px;
            font-weight: 600;
          }
        }
      }
      .actions {
        @include media-breakpoint-between(sm, lg) {
          flex-direction: row;
          .btn {
            font-size: 14px;
            max-width: 200px;
            height: 32px;
          }
        }
      }
    }
  }
  &.show {
    display: block;
  }
  .content {
    gap: 24px;
    align-items: center;
    flex-direction: column;

    .text {
      flex: 1;
      .title {
        line-height: 24px;
        font-size: 20px;
        font-weight: 700;
        color: $text;
        @include media-breakpoint-down(md) {
          font-size: 16px;
          line-height: 19px;
        }
      }
      p {
        color: $text;

        a {
          margin-left: 2px;
          color: $secondary;
          font-weight: 600;
        }
      }
    }
    .actions {
      width: 100%;
      display: flex;
      gap: 12px;
      flex-direction: column;
      .btn {
        width: 100%;
        margin: 0;
      }
    }
  }
}
</style>
